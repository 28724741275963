import { FC } from "react";
import styles from "./search-result-item.module.css";

type TSearchResultItemProps = {
  item: {
    title: string;
    headline: string;
    slug: string
  }
}

export const SearchResultItem: FC<TSearchResultItemProps> = ({ item }) => {


  return (
    <a href={item.slug} className={styles.link}>
      <h3 className={styles.title}>{item.title}</h3>
      <p className={styles.paragraph} dangerouslySetInnerHTML={{__html: item.headline}} />
    </a>
  )
}
