import { FC } from "react";
import { ContentTextList } from "../../components/content-text-list/content-text-list";
import { ContentImage } from "../../components/content-image/content-image";
import { ContentText } from "../../components/content-text/content-text";
import { ContentTitle } from "../../components/content-title/content-title";
import { testBlockData } from "../../constants/data-guide-components";
import styles from "./guide-components-page.module.css";
import { Video } from "../../components/video/video";
import Search from "../../components/search/search";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { Navigation } from "../../components/navigation/navigation";
import AccordionMenu from "../../components/accordion-menu/accordion-menu";
import { useMediaQuery } from "../../hooks/useMediaQuery";

/*
## http://localhost:3000/guide-components для проверки
## Реализация тестовой страницы с возможной разбивкой по компонентам
## text, img, title, video, list (моковые данные размещены в файле: testBlockData )
*/

export const GuideComponentsPage: FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <main className={styles.main}>
      <div>
        <Search />
        {!isMobile && (
          <div>
            <Search />
            <AccordionMenu
              title={"Информация для владельцев домашних архивов"}
            />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <Breadcrumbs />
        <ContentTitle title={testBlockData.title} />
        {testBlockData &&
          testBlockData.blocks.map((pageBlock: any) => {
            switch (pageBlock.layout) {
              case "text":
                return (
                  <ContentText
                    title={pageBlock.contents.title}
                    text={pageBlock.contents.items}
                    warning={pageBlock.warning}
                    link={pageBlock.link}
                    key={pageBlock.layout}
                  />
                );
            }
            switch (pageBlock.layout) {
              case "list":
                return (
                  <ContentTextList
                    listItem={pageBlock.contents.items}
                    key={pageBlock.layout}
                  />
                );
              case "image":
                return (
                  <ContentImage
                    url={pageBlock.contents.url}
                    caption={pageBlock.contents.caption}
                    key={pageBlock.layout}
                  />
                );
            }
            return "";
          })}
        <Video />
        <Navigation />
      </div>
    </main>
  );
};
