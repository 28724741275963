import { FC } from "react";
import { ExternalLink } from "../external-link/external-link";

import styles from "./popup.module.css";

import type { TAnchored } from "../../services/types/frontpage";

type TPopupProps = {
  data: TAnchored;
  closePopup: () => void;
}

export const Popup: FC<TPopupProps> = ({data, closePopup}) => {
  return (
    <div className={styles.popup}>
      <button
        type="button"
        onClick={closePopup}
        className={styles.popup__closeButton}
      />
      <div>
        <ExternalLink url={data.page_slug ?? data.url}>
          <h2 className={styles.popup__title}>{data.title}</h2>
          <p className={styles.popup__subtitle}>{data.subtitle}</p>
        </ExternalLink>
      </div>
    </div>
  );
};
