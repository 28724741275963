import { FC } from "react";
import { Link } from "react-router-dom";
import { DOMAIN } from "../../constants";

import styles from "./project-card.module.css";

import type { TItem } from "../../services/types/frontpage";

type TProjectCard = {
  readonly item: TItem;
}

export const ProjectCard: FC<TProjectCard> = ({ item }) => {
  return (
    <Link to={`${item.slug}`} className={styles.link}>
      <div className={styles.card}>
        <img
          className={styles.image}
          src={`${DOMAIN}${item.cover}`}
          alt={item.title}
        />
        <div className={styles.text}>
          <div className={styles.field}>
            <h3 className={styles.title}>{item.title}</h3>
            <p className={styles.annotation}>
              {item.annotation}
            </p>
          </div>
          <p className={styles.date}>{item.date_published}</p>
        </div>
      </div>
    </Link>
  )
}
