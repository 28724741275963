export const DOMAIN = "https://dev.archive.prozhito.org";
export const API_URL = `${DOMAIN}/api`;

export const NEWS_PAGE_LIMIT_DESKTOP = 9;
export const NEWS_PAGE_LIMIT_TABLET = 6;
export const NEWS_PAGE_LIMIT_MOBILE = 3;

export const JOURNAL_PAGE_LIMIT_DESKTOP = 9;
export const JOURNAL_PAGE_LIMIT_TABLET = 6;
export const JOURNAL_PAGE_LIMIT_MOBILE = 3;

export const MOBYLE_MEDIA_QUERY = "(max-width:767px)";
