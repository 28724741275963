import { FC } from "react";

import styles from "./tabs-item.module.css";

type TTabItemProps = {
  title: string;
  value : string;
  selected : boolean;
  setSelected : (value: string) => void;
}

export const TabsItem: FC<TTabItemProps> = ({title, value, selected, setSelected}) => {
  const tabsItemStylesLine = `${styles.line}`;

  return (
    <li className={styles.tab}>
      <button
        className={`${styles.button} ${selected && tabsItemStylesLine}`}
        onClick={() => {
          if (!selected) {
            setSelected(value)
          }
        }}
      >
        {title.toUpperCase()}
      </button>
    </li>
  )
}
