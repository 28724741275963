import {FC} from "react";
import {SearchResultItem} from "../search-result-item/search-result-item";
import styles from "./search-result-section.module.css";

type TSearchResultProps = {
  searchResults: { title: string, headline: string, slug: string }[] | null;
  query?: string;
}

export const SearchResultSection: FC<TSearchResultProps> = ({searchResults, query}) => {
  return (
    <div className={styles.searchResultSection}>
      {searchResults && (
        <div className={styles.resultsNumber}>
          Нашлось {searchResults.length} результатов по запросу “{query}”
        </div>
      )}
      {searchResults && searchResults.map((item, index) => (
          <SearchResultItem key={index} item={item}/>
        ))
        || <p className={styles.resultsNumber}>По вашему запросу ничего не найдено</p>
      }

    </div>
  )
}
