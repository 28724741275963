import { FC, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { API } from "../../services/api/data";
import { PopupSample } from "./popup/popup";
import { ContentsSample } from "./contents-sample/contents-sample";
import { Loader } from "../../components/loader/loader";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ContentsMobile } from "./contents-mobile/contents-mobile";

import styles from "./sample-page.module.css";

import arrowLeft from '../../images/Arrow_left.svg';
import arrowRight from '../../images/Arrow_right.svg';

import type { TContents } from "../../services/types/page";
import SampleImage, {TSampleImageProps} from "../../components/sample-image/sample-image";
import SampleGallery, {TSampleGalleryProps} from "../../components/sample-gallery/sample-gallery";
import { AudioPlayer } from "../../components/audio-player/audio-player";
import { Video } from "../../components/video/video";

type TGalleryState = {
  position: number;
  image?: string;
  text?: string;
};

export const SamplePage: FC = () => {
  const [popupOpen, setPopupOpen] = useState(true);
  const [contents, setContents] = useState<TContents>([]);
  const [gallery, setGallery] = useState<TGalleryState[]>([])
  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const [contentsOpen, setContentsOpen] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  // const sampleImageData: TSampleImageProps = {
  //   url: "https://posibiri.ru/wp-content/uploads/2020/04/030420.jpg",
  //   caption: "Подпись к картинке"
  // }
  // const sampleSliderData: TSampleGalleryProps = {
  //   data: [
  //     {
  //       url: "https://posibiri.ru/wp-content/uploads/2020/04/030420.jpg",
  //       caption: "Подпись к картинке 1"
  //     },
  //     {
  //       url: "https://prozhito.org//pictures/news/1641996224_158.png",
  //       caption: "Подпись к картинке 2"
  //     },
  //     {
  //       url: "https://posibiri.ru/wp-content/uploads/2020/04/030420.jpg",
  //       caption: "Подпись к картинке 3"
  //     },
  //   ],
  // }

  const location = useLocation();

  const { isLoading, data } = API.useGetPageBySlugQuery(location.pathname.slice(1));

  const mobile = useMediaQuery("(max-width: 767px)")

  const openContents = (): void => {
    setContentsOpen(true)
  }

  const onScroll = useCallback((): void => {
    const currentScrollY = window.pageYOffset;

    if ((scrollValue < currentScrollY && scrollingUp) || (currentScrollY === 0)) {
      setScrollingUp(false)
    }
    if (scrollValue > currentScrollY && !scrollingUp) {
      setScrollingUp(true)
    }
    setScrollValue(currentScrollY)
  }, [scrollValue, setScrollValue, scrollingUp])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [onScroll])

  useEffect(() => {
    const audioElement = document.querySelector<HTMLDivElement>(".player__container")
    if (audioElement == null) {
      return
    }
    const sourceElement = audioElement.querySelector<HTMLSourceElement>("#audio")
    if (sourceElement == null) {
      return
    }
    const playButton = audioElement.querySelector<HTMLButtonElement>(".player__button-play")
    const reverseAntiClockwiseButton = audioElement.querySelector<HTMLButtonElement>(".player__button-reverse-anticlockwise")
    const reverseClockwiseButton = audioElement.querySelector<HTMLButtonElement>(".player__button-reverse-clockwise")
    const muteButton = audioElement.querySelector<HTMLButtonElement>(".player__button-mute")

    const audio = new Audio(sourceElement.src)
    let duration: number
    let isPlaying: boolean

    const onLoadedMetaData = () => {
      duration = audio.duration
    }

    const onEnded = () => {
      isPlaying = false
      playButton?.classList.remove("player__button-stop")
      playButton?.classList.add("player__button-play")
    }

    const getTime = (num: number) => {
      let hours = Math.floor(num / 3600)
      let minutes = Math.floor((num - (hours * 3600)) / 60)
      let seconds = Math.floor(num - (hours * 3600) - (minutes * 60))
      if (hours === 0) {
        return `${String(minutes).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`
      }
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`
    }

    const timeElement = audioElement.querySelector(".player__time");
    if (timeElement != null) {
      setInterval(() => {
        timeElement.textContent = getTime(isPlaying || audio.paused ? audio.currentTime : duration)
      }, 500);
    }
    audio.addEventListener("loadedmetadata", onLoadedMetaData);
    audio.addEventListener("ended", onEnded);
    audio.addEventListener(
      "loadeddata",
      () => {
        audio.volume = .5;
      },
      false
    );
    if (playButton != null) {
      playButton.addEventListener(
        "click",
        () => {
          if (audio.paused) {
            isPlaying = true;
            playButton.classList.remove("player__button-play");
            playButton.classList.add("player__button-stop");
            audio.play().then(() => console.log("Playing..."));
          } else {
            isPlaying = false;
            playButton.classList.remove("player__button-stop");
            playButton.classList.add("player__button-play");
            audio.pause();
          }
        },
        false
      )
    }
    if (reverseAntiClockwiseButton) {
      reverseAntiClockwiseButton.addEventListener(
        "click",
        () => {
          if (audio.currentTime > 0) {
            audio.currentTime = Math.max(audio.currentTime - 60, 0)
          }
        }
      )
    }
    if (reverseClockwiseButton) {
      reverseClockwiseButton.addEventListener(
        "click",
        () => {
          if (audio.currentTime < duration) {
            audio.currentTime = Math.min(audio.currentTime + 60, duration)
          } else {
            isPlaying = false
            playButton?.classList.remove("player__button-stop")
            playButton?.classList.add("player__button-play")
          }
        }
      )
    }
    if (muteButton != null) {
      muteButton.addEventListener(
        "click",
        () => {
          audio.muted = !audio.muted
          if (audio.muted) {
            muteButton.classList.remove("player__button-mute")
            muteButton.classList.add("player__button-unmute")
          } else {
            muteButton.classList.remove("player__button-unmute")
            muteButton.classList.add("player__button-mute")
          }
        },
        false
      )
    }
    return () => {
      audio.removeEventListener("loadedmetadata", onLoadedMetaData)
      audio.removeEventListener("ended", onEnded)
    }
  })

  useEffect(() => {
    if (!isLoading || data) {
      const articleEl = document.querySelector(".article");
      const headersEl = articleEl?.querySelectorAll("h2");

      if (headersEl) {
        const res: TContents = Array.from(headersEl).map((item) => {
          return {
            title: item.innerText,
            position: item.offsetTop - item.offsetHeight
          }
        });

        setContents(res);
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!isLoading || data) {
      const galleryEl = document.querySelector('.gallery');
      const galleryList = galleryEl?.querySelectorAll('.image');

      if (galleryList) {
        const galleryState = Array.from(galleryList).map((item, index) => {
          const imgSrc = item.querySelector('img');
          const text = item.querySelector('figcaption');

          return {
            position: index + 1,
            image: imgSrc?.src,
            text: text?.innerText,
          }
        });

        if (galleryState.length > 0) {
          setGallery(galleryState);
          setCurrentImage(0);
        }
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (gallery.length > 0) {
      const galleryEl = document.querySelector('.gallery');
      const contentEl = galleryEl?.querySelector('.content');

      if (contentEl) {
        const handleForwardClick = () => {
          if (currentImage !== null && currentImage + 1 <= gallery.length - 1) {
            setCurrentImage(prev => prev !== null ? prev + 1 : null);
          }
        }

        const handleBackClick = () => {
          if (currentImage !== null && currentImage - 1 >= 0) {
            setCurrentImage(prev => prev !== null ? prev - 1 : null);
          }
        }

        // очищаем содержимое внутри тега div.content, чтобы создать заново теги по нужной нам структуре
        contentEl.innerHTML = '';

        // создаем основной контейнер для работы галереи, где будет происходить рендер
        const galleryContainerEl = document.createElement('figure');
        galleryContainerEl.classList.add('image');

        // создаем контейнер, где будет лежать текущее изображение и добавляем класс для быстрого поиска элемента
        const galleryImageEl = document.createElement('img');
        galleryImageEl.classList.add('currentImage');

        // создаем элемент стрелки "Вперед" и вешаем слушатель клика
        const galleryPaginationForwardEl = document.createElement('img');
        galleryPaginationForwardEl.classList.add('paginationArrow');
        galleryPaginationForwardEl.src = arrowRight;
        galleryPaginationForwardEl.addEventListener('click', handleForwardClick);

        // создаем элемент стрелки "Назад" и вешаем слушатель клика
        const galleryPaginationBackEl = document.createElement('img');
        galleryPaginationBackEl.classList.add('paginationArrow');
        galleryPaginationBackEl.src = arrowLeft;
        galleryPaginationBackEl.addEventListener('click', handleBackClick);

        // создаем элемент для отображение подписи к текущей картинки
        const galleryTextEl = document.createElement('div');
        galleryTextEl.classList.add('currentDesc');

        // создаем элемент, где будет лежать текущий номер картинки и цифра с количество всех картинок в галереи
        const galleryPaginationStepEl = document.createElement('div');
        galleryPaginationStepEl.classList.add('paginationStep');

        // создаем элемент, где будут лежать все данные о пагинации в галереи - кнопки и числа
        const galleryPaginationEl = document.createElement('div');
        galleryPaginationEl.classList.add('paginationContainer');

        // накидываем внутрь все блоки для пагинации (листалки)
        galleryPaginationEl.insertAdjacentElement("afterbegin", galleryPaginationForwardEl);
        galleryPaginationEl.insertAdjacentElement("afterbegin", galleryPaginationStepEl);
        galleryPaginationEl.insertAdjacentElement("afterbegin", galleryPaginationBackEl);

        const galleryDescContainerEl = document.createElement('figcaption');
        galleryDescContainerEl.classList.add('galleryContainer');

        galleryDescContainerEl.insertAdjacentElement("afterbegin", galleryPaginationEl);
        galleryDescContainerEl.insertAdjacentElement("afterbegin", galleryTextEl);

        galleryContainerEl.insertAdjacentElement("afterbegin", galleryDescContainerEl);
        galleryContainerEl.insertAdjacentElement("afterbegin", galleryImageEl);

        contentEl.insertAdjacentElement("afterbegin", galleryContainerEl);
      }
    }
  }, [gallery, data, currentImage]);

  useEffect(() => {
    if (currentImage !== null) {

      const imgEl: HTMLImageElement | null = document.querySelector('.currentImage');
      const textEl: HTMLDivElement | null = document.querySelector('.currentDesc');
      const stepEl: HTMLSpanElement | null = document.querySelector('.paginationStep');

      if (imgEl && gallery[currentImage].image) {
        imgEl.src = gallery[currentImage].image || '';
      }
      if (imgEl && gallery[currentImage].text) {
        imgEl.alt = gallery[currentImage].text || '';
      }

      if (textEl) {
        textEl.innerText = gallery[currentImage].text || '';
      }

      if (stepEl) {
        stepEl.innerText = String(`${currentImage + 1} / ${gallery.length}`);
      }
    }
  }, [currentImage, gallery]);

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <main className={styles.main}>
      <div className={styles.tag}>
        {data.tags.length > 0 && data.tags.map((tag, index) => {
          return <p className={styles.tagPart} key={index}>{tag}</p>
        })
        }
      </div>
      {contents.length > 0 && (
        <>
          <button type="button" className={styles.buttonContent} onClick={openContents}>
            оглавление
          </button>
          {
            contentsOpen && (
              <ContentsSample
                contents={contents}
                openContents={() => setContentsOpen(true)}
                closeContents={() => setContentsOpen(false)}
              />
            )
          }
        </>)}

      {
        popupOpen && (
          <PopupSample closePopup={() => setPopupOpen(false)} />
        )
      }
      {
        mobile && scrollingUp && (
          <ContentsMobile contents={contents} />
        )
      }
      <article className="article">
        <h1>{data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        {/*<SampleImage url={sampleImageData.url} caption={sampleImageData.caption}/>*/}
        {/*<SampleGallery data={sampleSliderData.data}/>*/}
        <AudioPlayer audioSrc="https://wavesurfer-js.org/example/elan/transcripts/001z.mp3"/>
        <Video/>
      </article>
    </main>
  )
}
