import { FC } from "react";
import styles from './guide-page.module.css'
import AccordionMenuApi from "../../components/accordion-menu-api/accordion-menu-api";
import { useParams } from "react-router-dom";
import { API } from "../../services/api/data";
import { Loader } from "../../components/loader/loader";
import { NavigationAPI } from "../../components/navigation-api/navigation-api";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ContentTitle } from "../../components/content-title/content-title";
import Search from "../../components/search/search";


export const GuidePage: FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  //достаем slug для определения того, какую страницу нужно запросить
  const { slug } = useParams();

  const { isLoading, data: pageData } = API.useGetHelpPageBySlugQuery(slug);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main className={styles.main}>
          {!isMobile && <div>
            <Search />
            <AccordionMenuApi data={pageData!.navigation} />
          </div>}
          <article>
            <ContentTitle title={pageData!.title} />
            <div className="article" dangerouslySetInnerHTML={{ __html: pageData!.content }}></div>
            <NavigationAPI data={{ next: pageData?.next_slug, prev: pageData?.prev_slug }} />
          </article>
        </main>
      )}
    </>
  )
}
