import { useState, useEffect, FC } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { API } from "../../services/api/data";
import { DOMAIN } from "../../constants";
import { LinkButton } from "../link-button/link-button";

import styles from "./banner.module.css";

import type { TCard, TBlock as TBannerBlock } from "../../services/types/frontpage";

export const Banner: FC = () => {
  const desktop = useMediaQuery("(min-width: 480px)");

  const { isLoading, data: pageData } = API.useGetFrontPageQuery();
  const [state, setState] = useState<TCard>();

  const handleClickURL = (url: string | null | undefined) => {
    if (url) window.open(url);
  };

  useEffect(() => {
    if (!isLoading && pageData) {
      const banner = pageData.blocks.find(
        (item) => item.layout === "card"
      ) as TBannerBlock;

      if (banner) {
        banner.cards?.forEach((item) =>
          setState({
            title: item.title,
            annotation: item.annotation,
            cover: item.cover,
            links: item.links,
          })
        );
      }
    }
  }, [pageData, isLoading]);

  const page_slug = state?.links[1].page_slug;
  const url = state?.links[0].url;

  return (
    <section className={styles.banner}>
      <article className={styles.banner__container}>
        <img
          className={styles.banner__image}
          src={`${DOMAIN}${state?.cover}`}
          alt={"фото"}
        />
        <h2 className={styles.banner__title}>{state?.title}</h2>
        <p className={styles.banner__text}>{state?.annotation}</p>
        <div className={styles.banner__buttonsWrapper}>
          {desktop ? (
            <>
              <LinkButton to={`${page_slug}`} color={false}>
                Перейти к материалу
              </LinkButton>
              <LinkButton onClick={() => handleClickURL(url)}>
                Оставить заявку
              </LinkButton>
            </>
          ) : (
            <>
              <LinkButton onClick={() => handleClickURL(url)}>
                Оставить заявку
              </LinkButton>
              <LinkButton to={`${page_slug}`} color={false}>
                Перейти к материалу
              </LinkButton>
            </>
          )}
        </div>
      </article>
    </section>
  );
};
