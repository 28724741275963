import { FC } from "react";
import { Link } from "react-router-dom";
import { DOMAIN } from "../../constants";
import anonymImage from "../../images/anonym.jpg"

import styles from "./materials-item.module.css";

import type { TCard } from "../../services/types/frontpage";

export const MaterialsItem: FC<TCard> = (props) => {
  const { title, cover, annotation, label, links } = props;

  const titleClassName =
    title.split(" ").length < 3
      ? `${styles.cardTitle} ${styles.cardTitle_short}`
      : `${styles.cardTitle}`;

  const handleErrorImageLoad = (evt: React.SyntheticEvent<HTMLImageElement>) => {
    const element = evt.target as HTMLImageElement;
    element.src = anonymImage;
  }

  return (
    <li className={styles.card}>
      <Link
        to={`${links[0].page_slug}`}
        className={styles.card__link}
      >
        <p className={styles.card__tag}>{label}</p>
        <p className={titleClassName}>{title}</p>
        <img
          src={`${DOMAIN}${cover}`}
          alt={`фотография ${title}`}
          className={styles.card__image}
          onError={handleErrorImageLoad}
        />
        <p className={styles.card__text}>{annotation}</p>
      </Link>
    </li>
  );
};
