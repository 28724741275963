import { FC } from "react";

import styles from "./popup.module.css";

interface IPopupProps {
  closePopup: () => void;
}

export const PopupSample: FC<IPopupProps> = ({ closePopup }) => {

  return (
    <div className={styles.popup}>
      <button
        type="button"
        onClick={closePopup}
        className={styles.popup__closeButton}
      />
      <article className={styles.popup__article}>
        <h2 className={styles.popup__title}>
          Это верно
        </h2>
        <p className={styles.popup__subtitle}>
          Информация достоверна
        </p>
      </article>
    </div>
  );
};
