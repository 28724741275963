import { useEffect, useState } from "react";

export const useMediaQuery = (query: string): boolean => {

    const getMatches = (q: string): boolean => {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            return window.matchMedia(q).matches;
        }

        return false;
    }

    const [matches, setMatches] = useState<boolean>(getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query);
        // Triggered at the first client-side load and if query changes
        handleChange();
        // Listen matchMedia
        matchMedia.addEventListener("change", handleChange);
        return () => matchMedia.removeEventListener("change", handleChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return matches;
}
