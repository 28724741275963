import React from 'react';
import AccordionRow from "../accordion-row/accordion-row";
import styles from './accordion-menu.module.css';

interface IAccordionMenuProps {
  title?: string;
}

const AccordionMenu = ({title}: IAccordionMenuProps) => {
  return (
    <nav className={styles.menu__wrapper}>
      <h2 className={styles.menu__title}>{title}</h2>
      <AccordionRow title={'Копирование документов'} isExpanded={true}>
        <ul className={styles.menu__list}>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Выбор типа копирования</a></li>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Разрешение копирования и формат файла</a></li>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Полнота копирования</a></li>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Названия файлов</a></li>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Дополнительные рекомендации к сканированию</a></li>
          <li><a className={styles.menu__link} href={'https://youtu.be/dQw4w9WgXcQ'}>Дополнительные рекомендации к фотокопированию</a></li>
        </ul>
      </AccordionRow>
      <AccordionRow title={'Описание документов'}/>
      <AccordionRow title={'Карточка персоны'}/>
      <AccordionRow title={'Подготовка текста'}/>
      <AccordionRow title={'Разметка'}/>
    </nav>
  );
};

export default AccordionMenu;
