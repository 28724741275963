import { FC } from "react";

import styles from "./contents-sample.module.css";

import type { TContents } from "../../../services/types/page";



type TContentsSampleProps = {
  contents: TContents;
  openContents?: () => void;
  closeContents: () => void;
};

export const ContentsSample: FC<TContentsSampleProps> = ({ contents, closeContents }) => {
  const handleClick = (top: number) => {
    window.scrollTo({top});
    closeContents();
  }

  return (
    <div className={styles.table}>
      <div className={styles.table__container}>
        <button
          type="button"
          onClick={closeContents}
          className={styles.table__close}
        >
        </button>

        {
          contents.map((item, index) => {
            return <h2
              className={styles.table__title}
              onClick={() => handleClick(item.position)}
              key={index}
            >
              {item.title}
            </h2>;
          })
        }

        {
          contents.map((item, index) => {
            return <ul
            >
              <li key={index} >  
                {item.content}
              </li>
            </ul>;
          })
        }

      </div>
    </div>
  )
}

