import React from 'react';
import AccordionRow from "../accordion-row-api/accordion-row-api";
import styles from './accordion-menu-api.module.css';
import {TAccordionRow} from "../../services/types/help";

interface IAccordionMenuProps {
  data: TAccordionRow[]
}

const AccordionMenuApi = ({data}: IAccordionMenuProps) => {
  return (
    <nav className={styles.menu__wrapper}>
      {/*<h2 className={styles.menu__title}>{data.title}</h2>*/}
      {data.map((row) =>
        <AccordionRow title={row.title} content={row.children} isExpanded={row.current} key={row.title}/>)}
    </nav>
  );
};

export default AccordionMenuApi;
