import { FC } from "react";
import styles from "./content-title.module.css";

interface IContentTitle {
  title?: string;
}

export const ContentTitle: FC<IContentTitle> = ({ title }) => {

  return (
      <h1 className={styles.title}>{title}</h1>
  )
};

