import React, {FC} from "react";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {LinkButton} from "../link-button/link-button";
import styles from "./search.module.css";
import {setIsSearching} from "../../services/slices/search";
import {useDispatch} from "../../hooks";
import {useNavigate} from "react-router";

declare type TSearchInterface = {
  onChange?: React.FormEventHandler<HTMLInputElement>;
  value?: string;
};
const Search: FC<TSearchInterface> = ({ onChange, value }) => {
  const desktop = useMediaQuery("(min-width: 650px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearch = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(setIsSearching(true))
    navigate(`/help?s=${value}`);
  };

  return (
    <form className={styles.container} onSubmit={onSearch}>
      <input
        className={styles.input}
        id="search_input"
        type="text"
        name="search_input"
        required
        maxLength={200}
        autoComplete="on"
        placeholder="Поиск в справке"
        onChange={onChange}
        value={value}
      />
      <LinkButton rectSmall type="submit" size="small">
        {desktop ? "Найти" : ""}
      </LinkButton>
    </form>
  );
};

export default Search;
