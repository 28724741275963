import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { API } from "../../services/api/data";

import styles from "./breadcrumbs.module.css";

type TBreadcrump = {
  url: string;
  title: string;
}

export const Breadcrumbs: FC = () => {
  const initialBreadcrump: TBreadcrump = {
    url: "/",
    title: "Главная страница"
  };

  const { pathname } = useLocation();

  const pageType =
    (pathname === "/news" || pathname === "/journal")
      ? "category"
      : "page";

  const { isLoading, data } = API.useGetBreadcrumpsQuery(
    {
      part: pageType,
      slug: pathname.slice(1)
    },
    { refetchOnMountOrArgChange: true }
  );

  const [crumps, setCrumps] = useState<TBreadcrump[]>([]);

  useEffect(() => {
    if(!isLoading) {
      const res: TBreadcrump[] = [initialBreadcrump];

      if(data && data?.category?.title) {
        res.push({
          url: data?.category?.slug,
          title: data.category.title
        });
      }

      if(data && data?.title) {
        res.push({
          url: data?.slug,
          title: data.title
        });
      }

      setCrumps(res);
    }

  }, [isLoading, data]); // eslint-disable-line

  if (pathname === "/") {
    return null
  }

  return(
      <nav className={styles.nav}>
        <p className={styles.title}>
          {crumps.length > 0 &&
            crumps.map((item, index) => {
              if(index === crumps.length-1 && crumps.length !== 1) {
                return <span key={index} className={styles.textlast}>{item.title}</span>;
              } else {
                return <span key={index}><Link className={styles.text} to={item.url}>{item.title}</Link> / </span>;
              }
            })
          }
        </p>
      </nav>
  );
}
