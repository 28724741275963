import { FC } from "react";
import { API } from "../../services/api/data";
import { FooterInfo } from "../footer-info/footer-info";
import { FooterLinks } from "../footer-links/footer-links";

import styles from "./footer.module.css";

export const Footer: FC = () => {
  const { data: contextData, isLoading } = API.useGetContextQuery();

  return (
    <footer className={styles.footer}>
      {!isLoading && (
        <>
          <FooterLinks links={contextData} />
          <FooterInfo links={contextData} />
        </>
      )}
    </footer>
  );
};
