import { FC, useEffect, useState, useCallback } from "react";
import { API } from "../../services/api/data";
import { useDispatch, useSelector } from "../../hooks";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  JOURNAL_PAGE_LIMIT_DESKTOP,
  JOURNAL_PAGE_LIMIT_TABLET,
  JOURNAL_PAGE_LIMIT_MOBILE,
} from "../../constants";
import {
  dataJournalSelector,
  filterJournalSelector,
  pageJournalSelector,
  totalJournalSelector,
  tabsJournalSelector,
} from "../../services/selectors/journal";
import {
  setJournalFilter,
  setJournalPage,
} from "../../services/slices/journal";
import { Tabs } from "../../components/tabs/tabs";
import { JournalItem } from "../../components/journal-item/journal-item";
import { LinkButton } from "../../components/link-button/link-button";
import { Loader } from "../../components/loader/loader";

import styles from "./journal-page.module.css";

import type { TJournalTabs } from "../../services/types/journal";

export const JournalPage: FC = () => {
  const dispatch = useDispatch();

  const filter = useSelector(filterJournalSelector);
  const page = useSelector(pageJournalSelector);
  const totalCount = useSelector(totalJournalSelector);
  const journal = useSelector(dataJournalSelector);
  const tabsList = useSelector(tabsJournalSelector);

  const [tabs, setTabs] = useState<TJournalTabs>([
    { title: "Все", slug: "all" },
  ]);
  const [selectedTab, setSelectedTab] = useState<string>(filter);

  useEffect(() => {
    let res: TJournalTabs = tabsList.filter((item) => {
      if (!tabs.find((tab) => tab.slug === item.slug)) {
        return item;
      }

      return false;
    });

    if (res.length > 0) {
      res = tabs.concat(res);
      setTabs(res);
    }
  }, [tabsList, tabs]);

  const tablet = useMediaQuery("(max-width: 1024px)");
  const mobile = useMediaQuery("(max-width: 425px)");

  let pageSize = JOURNAL_PAGE_LIMIT_DESKTOP;
  if (tablet) {
    pageSize = JOURNAL_PAGE_LIMIT_TABLET;
  }
  if (mobile) {
    pageSize = JOURNAL_PAGE_LIMIT_MOBILE;
  }

  const { isLoading, isFetching, data } = API.useGetJournalQuery(
    {
      tag: filter === "all" ? undefined : filter,
      page,
      pageSize,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleFilter = useCallback((value: string) => {
    setSelectedTab(value);
    dispatch(setJournalFilter(value));
  }, [setSelectedTab, dispatch]);

  const handleLoad = useCallback(() => {
    dispatch(setJournalPage(page + 1));
  }, [page, dispatch]);

  if (isLoading || isFetching)
    return <Loader />;

  return (
    <main className={styles.main}>
      <h1 className={styles.heading}>
        {data?.title}
      </h1>
      <Tabs
        tabs={tabs}
        onClick={handleFilter}
        current={selectedTab}
      />

      {!isLoading && journal && (
        <ul className={styles.list}>
          {journal.map((item) => (
            <li
              className={styles.list__item}
              key={item.slug}
            >
              <JournalItem item={item} layout={item.layout} />
            </li>
          ))}
        </ul>
      )}
      { totalCount > journal?.length &&
        <div className={styles.buttonContainer}>
          <LinkButton
            type={"button"}
            onClick={handleLoad}
            disabled={totalCount === journal?.length}
          >
            Загрузить еще
          </LinkButton>
        </div>
      }
    </main>
  );
};
