import styles from './audio-player.module.css'
import WaveSurfer from 'wavesurfer.js'
import { useEffect, useRef, useState } from 'react'

type TAudioPlayer = {
  audioSrc: string
  signature?: string
  wrapperStyle?: string
  signatureStyle?: string
}

type TAudioDuration = {
  hours: number
  minutes: number
  seconds: number
}

// prettier-ignore
export const AudioPlayer = ({ audioSrc, signature, wrapperStyle, signatureStyle }: TAudioPlayer) => {
  const [playButton, setPlayButton] = useState<boolean>(false)
  const [muteButton, setMuteButton] = useState<boolean>(false)
  const [waveLoaded, setWaveLoaded]=useState<boolean>(false)
  // Если существует возможность проверить тему, проверяем ее булево, иначе будет светлая тема
  const darkTheme = window.matchMedia? !!window.matchMedia('(prefers-color-scheme: dark)').matches : false
  // Кнопки
  const playButtonRef = useRef(null)
  const forwardButtonRef = useRef(null)
  const backwardButtonRef = useRef(null)
  const soundButtonRef = useRef(null)
  // Реф хендлера
  const waveHandlerRef = useRef<HTMLDivElement|null>(null)
  // =====
  const [wave, setWave] = useState<any | null>(null) //any сменить на Wavesurfer
  const [time, setTime] = useState<string | null>('')
  const [audioDuration, setAudioDuration] = useState<TAudioDuration>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })


  // Объявление базового Wave
  useEffect(() => {
    const waveHandler = waveHandlerRef.current
    if (!wave){
      if (waveHandler && waveHandler.childNodes.length <= 1) {
        const newWave = WaveSurfer.create({
          container: waveHandler,
          barGap: 4.5,
          audioRate: 1,
          barWidth: 1.5,
          barRadius: 1,
          height: 45,
          responsive: true,
          hideScrollbar: true,
          partialRender: true,
          cursorColor: 'transparent',
          fillParent: true,
          // Цвет заливки и цвет самой волны, цвета для темной темы пока не указаны в макете
          progressColor: darkTheme? 'black' : 'black',
          waveColor: darkTheme? 'white' : '#999'
        })
        setWave(newWave)
        // Не занимаем место под волну, пока она не будет готова.
        newWave.container.querySelector('wave').style.display = 'none'
        newWave.load(audioSrc)
      }
    }
  }, [wave, darkTheme, waveHandlerRef, audioSrc])

  const convertNumberToTime = (num: number) => {
    const secInHour = 3600
    const secInMin = 60
    let allTime = num
    // Hours
    const hours = Math.floor(allTime / secInHour)
    allTime -= hours * secInHour
    // Minutes
    const minutes = Math.floor(allTime / secInMin)
    allTime -= minutes * secInMin
    // Seconds
    const seconds = Math.round(allTime)
    return { hours, minutes, seconds }
  }

  // Проверка на необходимость ставить 0 перед числом
  const setZeroBeforeNum = (num: number) => (num < 10 && num >= 0 ? `0${num}` : num)

  const setWaveCurrentTime = () => {
    const duration = audioDuration
    const { hours, minutes, seconds } = convertNumberToTime(wave!.getCurrentTime())
    // prettier-ignore
    const resultString = `${duration.hours>0? setZeroBeforeNum(hours)+':' : ''}${setZeroBeforeNum(minutes)}:${setZeroBeforeNum(seconds)}`
    setTime(resultString)
  }

  // Листенеры wave модуля
  if (wave) {
    // Повторная проверка на отображение сообщения загрузки (в случае ререндера компонента)
    wave.on('loading', () => {
      wave.container.querySelector('wave').style.display = 'none'
      setWaveLoaded(false)
    })

    // Расчет продолжительности трека
    wave.on('ready', () => {
      wave.container.querySelector('wave').style.display = 'block'
      setWaveLoaded(true)
      const { hours, minutes, seconds } = convertNumberToTime(wave.getDuration())
      // prettier-ignore
      const resultString = `${hours>0? setZeroBeforeNum(hours)+':' : ''}${setZeroBeforeNum(minutes)}:${setZeroBeforeNum(seconds)}`
      setAudioDuration({ hours, minutes, seconds })
      setTime(resultString)
    })

    // Текущее время трека
    wave.on('audioprocess', setWaveCurrentTime)

    // Время трека при перемотке курсором
    wave.on('seek', () => !playButton && setWaveCurrentTime())
  }

  useEffect(() => {
    if (wave) {
      playButton ? wave.play() : wave.pause()
    }
  }, [wave, playButton])

  useEffect(() => {
    if (wave) {
      muteButton ? wave.setMute(true) : wave.setMute(false)
    }
  }, [wave, muteButton])

  const onClickPlay = () => setPlayButton((prev) => !prev)
  const onClickMute = () => setMuteButton((prev) => !prev)

  const onClickBack = () => wave?.skipBackward(5)
  const onClickForward = () => wave?.skipForward(5)

  return (
    <div className={`${styles.wrapper} ${wrapperStyle ? wrapperStyle : ''}`}>
      <div className={styles.container}>
        <div className={styles.audioPlayer}>
          <button
            ref={playButtonRef}
            className={`${styles.audioPlayerPlay} ${playButton ? styles.audioPlayerPause : ''} ${waveLoaded ? '' : styles.blockedButton}`}
            onClick={onClickPlay}
          />

          <button
            ref={backwardButtonRef}
            className={`${styles.audioPlayerBack} ${waveLoaded ? '' : styles.blockedButton}`}
            onClick={onClickBack}
          />

          <button
            ref={forwardButtonRef}
            className={`${styles.audioPlayerForward} ${waveLoaded ? '' : styles.blockedButton}`}
            onClick={onClickForward}
          />

          <div className={styles.waveHandler} ref={waveHandlerRef}>
            {!waveLoaded && <span className={styles.waveLoadMessage}>Загрузка...</span>}
          </div>
          <span className={styles.audioPlayerTime}>{time}</span>

          <button
            ref={soundButtonRef}
            className={`${styles.audioPlayerSound} ${muteButton ? styles.audioPlayerSoundMuted : ''} ${waveLoaded ? '' : styles.blockedButton}`}
            onClick={onClickMute}
          />
        </div>
        {signature && (
          <span className={`${styles.audioPlayerSignature} ${signatureStyle ? signatureStyle : ''}`}>
            {signature}
          </span>
        )}
      </div>
    </div>
  )
}
