import { FC } from "react";

import styles from "./intro-main.module.css";

interface IIntroData {
  data: string
}

export const IntroMain: FC<IIntroData> = ({data}) => {

  return (
    <section className={styles.intro}>
      <div className={styles.intro__container}>
        {data &&
          (<h1 className={styles.intro__title}>
            {data}
          </h1>)
        }
      </div>
    </section>
  )
}
