import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { DOMAIN } from "../../constants";

import styles from "./news-item.module.css";

export type TNewsItem ={
  title?: string;
  date?: string;
  tag?: string[];
  image: string | null;
  text?: string;
  key?: string;
  path?: string | null;
}

export const NewsItem: FC<TNewsItem> = ({
  title,
  date,
  tag,
  image,
  text,
  path
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if(path) {
      navigate(`/${path}`);
    }
  }

  return (
    <article className={styles.newsItem} onClick={handleClick}>
      <div className={styles.newsItem__titleContainer}>
        {
          date && (
             <time dateTime={date} className={styles.newsItem__date}>
              {date}
             </time>
          )
        }
        <h3 className={styles.newsItem__title}>{tag}</h3>
      </div>
      <img
        width={212}
        height={223}
        src={`${DOMAIN}${image}`}
        alt={title ?? "Картинка к новости"}
        className={styles.newsItem__image}
      />
      <p className={styles.newsItem__text}>{text}</p>
    </article>
  );
};
