import { FC } from "react";
import { Link } from "react-router-dom";
import arrowLeft from '../../images/arrow_in_circle_left.svg'
import arrowRight from '../../images/arrow_in_circle_right.svg'
import styles from './navigation.module.css'

export const Navigation: FC = () => {
  return (
    <div className={styles.container}>
      <Link to='/help' className={styles.link}>
        <img src={arrowLeft} alt='Стрелка влево' />
        <p className={`${styles.linkText} ${styles.linkTextLeft}`}>Ссылка на предыдущую статью</p>
      </Link>
      <Link to='/help' className={styles.link}>
        <p className={`${styles.linkText} ${styles.linkTextRight}`}>Ссылка на следующую статью</p>
        <img src={arrowRight} alt='Стрелка вправо' />
      </Link>
    </div>
  )
}
