import { FC } from "react";
import { ExternalLink } from "../external-link/external-link";

import styles from "./footer-links.module.css";

import type { TContext } from "../../services/types/context";

// Типизацию потом поправлю
interface IFooterLinks {
  links: TContext | undefined;
}

export const FooterLinks: FC<IFooterLinks> = ({ links }) => {
  return (
    <section className={styles.container}>
      <ul className={styles.links}>
        {links?.footer_menu.map((link) => (
          link.url && <li className={styles.item} key={link.title}>
            <ExternalLink
              url={link.url}
              children={link.title}
              className={styles.link}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
