import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../api/data";
import { NEWS_PAGE_LIMIT_DESKTOP } from "../../constants";

import type { TNewsState } from "../types/news";

const initialState: TNewsState = {
  title: "",
  slug: "",
  archive_layout: "",
  pageSize: NEWS_PAGE_LIMIT_DESKTOP,
  page: 1,
  totalCount: 0,
  totalPages: 0,
  category_tags: [],
  items: [],
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(API.endpoints.getNews.matchFulfilled, (state, action) => {
        if (state.page === 1) {
          state.title = action.payload.title;
          state.slug = action.payload.slug;
          state.archive_layout = action.payload.archive_layout;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.items = action.payload.items;
          state.category_tags = action.payload.category_tags;
        } else {
          state.items = state.items.concat(action.payload.items);
        }
      })
      .addMatcher(API.endpoints.getNews.matchRejected, (state, action) => {
        if (action.error.name !== "ConditionError") {
          return state;
        }
      })
  },
})

export const { setNewsPage } = newsSlice.actions

export default newsSlice.reducer;
