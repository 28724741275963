import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { DOMAIN } from "../../constants";

import styles from "./journal-item.module.css";

import type { TItem } from "../../services/types/frontpage";

interface IJournalItemProps {
  item: TItem;
  layout: string;
}

export const JournalItem: FC<IJournalItemProps> = ({ item, layout }) => {
  const navigate = useNavigate();

  const imgSrc = item.cover ? item.cover.startsWith(DOMAIN) ? item.cover : `${DOMAIN}${item.cover}` : "";

  const handleClick = () => {
    if(item.slug) {
      navigate(`/${item.slug}`);
    }
  }

  return layout === "round" ? (
    <article className={`${styles.cardExp}`} onClick={handleClick}>
      <p className={styles.name}>{item.title}</p>
      <img
        src={imgSrc}
        className={styles.imgExp}
        alt={item.title}
      />
      <p className={`${styles.quoteExp}`}>{item.annotation}</p>
      <p className={styles.typeExp}>Опыт читателя</p>
    </article>
  ) : (
    <article className={styles.card} onClick={handleClick}>
      <img
        src={imgSrc}
        className={styles.img}
        alt={item.title}
      />
      <div className={styles.text}>
        <p className={styles.type}>Тематическая подборка</p>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.subtitle}>{item.subtitle}</p>
        <p className={styles.quote}>{item.annotation}</p>
      </div>
    </article>
  );
};
