import React, {ChangeEvent, FC, useEffect, useState} from "react";
import styles from "./help-page.module.css";
import Search from "../../components/search/search";
import {useLocation} from "react-router";
import {SectionList} from "../../components/section-list/section-list";
import {SearchResultSection} from "../../components/search-result-section/search-result-section";
import {API} from "../../services/api/data";
import {useDispatch, useSelector} from "../../hooks";
import {setIsSearching} from "../../services/slices/search";
import {searchDataInProgressSelector} from "../../services/selectors/search";
import {TSearchItem} from "../../services/types/search";

export const HelpPage: FC = () => {
  const {search} = useLocation();
  const dispatch = useDispatch()
  const decodedQuery = decodeURI(search.slice(search.indexOf("=") + 1));
  const [value, setValue] = useState(decodedQuery);
  const isSearchInProgress = useSelector(searchDataInProgressSelector);
  const [searchResult, setSearchData] = useState<TSearchItem[]>([])

  const searchData = API.useGetArchiveQuery(decodedQuery);
  const {data} = API.useGetHelpPageQuery();

  useEffect(() => {
    if (search) {
      setValue(decodeURI(decodedQuery));
    }
  }, [search]);

  useEffect(() => {
    if (isSearchInProgress) {
      dispatch(setIsSearching(false))
    }
    setSearchData(searchData.data as TSearchItem[])

  }, [isSearchInProgress, search, searchData])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }

  return (
    <main className={styles.main}>
      <h1 className={styles.title}>Справка «Прожито»</h1>
      <section className={styles.search}>
        <Search onChange={onChange} value={value}/>
      </section>
      {search ? (
        <SearchResultSection searchResults={searchResult} query={decodedQuery}/>
      ) : (
        <section className={styles.table}>
          {data?.index.map((element, index) => {
            return (
              <SectionList
                key={index}
                pageToRout={element.slug}
                title={element.title}
                icon={element.icon}
                links={element.children}
              />
            );
          })}
        </section>
      )}
    </main>
  );
};
