import { FC } from "react";
import { IntroItem } from "../intro-item/intro-item";

import styles from "./intro-card.module.css";

import type { TCard } from "../../services/types/frontpage";

interface IIntroCard {
  data: TCard[]
}

export const IntroCard: FC<IIntroCard> = ({data}) => {

  return (
    <section className={styles.intro}>
      <div className={styles.intro__cardContainer}>
        <ul className={styles.intro__cards}>
          {data &&
            data.map((item, index) => (
              <IntroItem
                title={item.title}
                text={item.annotation}
                link={item.links[0]}
                key={index}
              />
            ))
          }
        </ul>
      </div>
    </section>
  )
}
