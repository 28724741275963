import styles from "./section-list.module.css";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface IMenuItem {
  children: string;
  pageToRout: string;
}

const MenuItem: FC<IMenuItem> = ({ children, pageToRout }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/help/page/${pageToRout}/`);
  };
  return (
    <p className={styles.text} onClick={handleClick}>
      {children}
    </p>
  );
};

interface ISectionList {
  pageToRout: string;
  title: string;
  links: Array<{ title: string; slug: string }>;
  icon: string | null;
}

export const SectionList: FC<ISectionList> = ({
  pageToRout,
  title,
  icon,
  links,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/help/page/${pageToRout}/`);
  };
  return (
    <article className={styles.container}>
      <div className={styles.circle}>
        {icon && (
          <img className={styles.image} src={icon} alt={"Значок секции"} />
        )}
      </div>
      <div className={styles.wrapper}>
        <p className={styles.title} onClick={handleClick}>
          {title}
        </p>
        {links.map((link, index) => (
          <MenuItem pageToRout={link.slug} key={index}>
            {link.title}
          </MenuItem>
        ))}
      </div>
    </article>
  );
};