import { Link } from "react-router-dom";
import arrowLeft from '../../images/arrow_in_circle_left.svg'
import arrowRight from '../../images/arrow_in_circle_right.svg'
import { TLink } from "../../services/types/help";
import styles from '../navigation/navigation.module.css'

interface INavigationProps {
  data: {
    next: TLink | null | undefined,
    prev: TLink | null | undefined
  }
}

export const NavigationAPI = ({ data }: INavigationProps) => {
  return (
    <div className={styles.container}>
      {data.prev &&
        <Link to={`/guide/${data?.prev?.slug}`} className={styles.link}>
          <img src={arrowLeft} alt='Стрелка влево' />
          <p className={`${styles.linkText} ${styles.linkTextLeft}`}>{data?.prev?.title}</p>
        </Link>
      }
      {data.next &&
        <Link to={`/guide/${data?.next?.slug}`} className={styles.link}>
          <p className={`${styles.linkText} ${styles.linkTextRight}`}>{data?.next?.title}</p>
          <img src={arrowRight} alt='Стрелка вправо' />
        </Link>
      }
    </div>
  )
}
