import { FC } from "react";
import styles from "./content-text-list.module.css";

interface IContentTextList {
  listItem?: string[];
}

export const ContentTextList: FC<IContentTextList> = ({ listItem }) => {

  return (
    <ul className={styles.list}>
      {listItem?.map((item) => {
        return (
        <li className={styles.list_item}>{item}</li>)
      })}
    </ul>
  )
};
