import { useState, useEffect, FC } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { API } from "../../services/api/data";
import { MaterialsItem } from "../materials-item/materials-item";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./materials.module.css";

import type { TBlock as TMaterialslBlock } from "../../services/types/frontpage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const Materials: FC = () => {
  const desktop = useMediaQuery("(min-width: 768px)");

  const { isLoading, data: pageData } = API.useGetFrontPageQuery();
  const [materialslsList, setMaterialsList] = useState(Array<JSX.Element>);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (!isLoading && pageData) {
      const materials = pageData.blocks.find(
        (item) => item.layout === "cards"
      ) as TMaterialslBlock;

      if (materials?.title) {
        setTitle(materials.title);
      }

      if (materials.cards) {
        setMaterialsList(
          materials.cards.map((item, index) => {
            return (
              <MaterialsItem
                key={index}
                title={item.title}
                cover={item.cover}
                annotation={item.annotation}
                label={item.label}
                links={item.links}
              />
            );
          })
        );
      }
    }
  }, [pageData, isLoading]);

  return (
    <section className={styles.materials}>
      <h2 className={styles.title}>{title}</h2>

      {desktop ? (
        <ul className={styles.cards}>{materialslsList}</ul>
      ) : (
        <>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={12}
            slidesPerView={"auto"}
            pagination={{
              el: ".pagination",
              clickable: true
            }}
            className={styles.swiper}
          >
            {materialslsList.map((item, index) => {
              return (
                <SwiperSlide className={styles.swiperSlide} key={index}>
                  {item}
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={`${styles.pagination} pagination`} />
        </>
      )}
    </section>
  );
};
