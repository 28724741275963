import {TSearchData} from "../types/search";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: TSearchData = {
  isSearching: false,
}

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
  },
})

export const { setIsSearching } = searchSlice.actions

export default searchSlice.reducer;

