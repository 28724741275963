import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../api/data";
import { JOURNAL_PAGE_LIMIT_DESKTOP } from "../../constants";

import type { TJournalData } from "../types/journal";

const initialState: TJournalData = {
  page: 1,
  size: JOURNAL_PAGE_LIMIT_DESKTOP,
  filter : "all",
  totalCount: 0,
  data: [],
  tags: [],
}

export const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    setJournalPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setJournalFilter: (state, action: PayloadAction<string>) => {
      state.page = 1;
      state.data = [];
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(API.endpoints.getJournal.matchFulfilled, (state, action) => {
        state.tags = action.payload.category_tags;
        state.totalCount = action.payload.totalCount;

        if (state.page === 1) {
          state.data = action.payload.items;
        } else {
          state.data = state.data.concat(action.payload.items);
        }
      })
      .addMatcher(API.endpoints.getJournal.matchRejected, (state, action) => {
        if (action.error.name !== "ConditionError") {
          state.data = [];
          state.totalCount = 0;
          state.page = 1;
          state.filter = "all";
        }
      })
  },
})

export const { setJournalPage, setJournalFilter } = journalSlice.actions

export default journalSlice.reducer;
