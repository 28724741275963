import { FC } from "react";
import { API } from "../../services/api/data";
import { LinkButton } from "../link-button/link-button";
import { ProjectCard } from "../project-card/project-card";

import styles from "./project.module.css";

export const Project: FC = () => {

  const { data: frontPageData } = API.useGetFrontPageQuery()

  const card = frontPageData?.blocks.filter(e =>
    e.layout === "special")[0].category?.items;
  const title = frontPageData?.blocks.filter(e =>
    e.layout === "special")[0].category?.title;

  return (
    <section className={styles.project}>
      <h1 className={styles.title}>{title}</h1>
      {card &&
        <ul className={styles.container}>
          {card.slice(0, 2).map(item => (
            <li key={item.title}>
              <ProjectCard item={item} />
            </li>
          ))}
        </ul>}
      <div className={styles.button}>
        <LinkButton to={"/not/found"}>
          Все спецпроекты
        </LinkButton>
      </div>
    </section>
  )
}
