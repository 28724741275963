import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { NewsPage } from '../../pages/news-page/news-page'
import { MainPage } from '../../pages/main-page/main-page'
import { JournalPage } from '../../pages/journal-page/journal-page'
import { HelpPage } from '../../pages/help-page/help-page'
import { Layout } from '../layout/layout'
import { ServerRoute } from '../server-route/server-route'

import styles from "./app.module.css";
import { GuidePage } from "../../pages/guide-page/guide-page";
import { GuideComponentsPage } from "../../pages/guide-components-page/guide-components-page";

const App: FC = () => {
  return (
    <div className={styles.app}>
      <Layout>
        <Routes>
          <Route path={"/"} element={<MainPage />} />
          <Route path={"/news"} element={<NewsPage />} />
          <Route path={"/journal"} element={<JournalPage />} />
          <Route path={"/help"} element={<HelpPage />} />
          <Route path={"/guide/:slug"} element={<GuidePage />} />
          <Route path={"/guide/*"} element={<GuideComponentsPage />} />
          <Route path={"*"} element={<ServerRoute />} />
        </Routes>
      </Layout>
    </div>
  )
}

export default App
