import React, {useState} from 'react';
import styles from './accordion-row.module.css'

interface IAccordionProps {
  title: string,
  children?: React.ReactNode,
  isExpanded?: boolean
}

const AccordionRow = ({title, children, isExpanded = false}: IAccordionProps) => {

  const [isActive, setIsActive] = useState(isExpanded);

  return (
    <div className={styles.accordion__row}>
      <div className={`${styles.accordion__title} ${isActive && styles.accordion__title_active}`}
           onClick={() => setIsActive(!isActive)}>
        <div className={`${styles.accordion__arrow} ${isActive && styles.accordion__arrow_opened}`}></div>
        <div>{title}</div>
      </div>
      <div className={styles.accordion__content} aria-expanded={isActive}>
        {children}
      </div>
    </div>
  );
};

export default AccordionRow;
