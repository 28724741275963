import { FC, useState } from "react";
import { API } from "../../services/api/data";
import { Materials } from "../../components/materials/materials";
import { Popup } from "../../components/popup/popup";
import { Project } from "../../components/project/project";
import { Banner } from "../../components/banner/banner";
import { Loader } from "../../components/loader/loader";
import { NewsSection } from "../../components/news-section/news-section";
import { JournalSection } from "../../components/journal-section/journal-section";
import { IntroMain } from "../../components/intro-main/intro-main";
import { IntroCard } from "../../components/intro-card/intro-card";
import { Video } from "../../components/video/video";

export const MainPage: FC = () => {
  const { isLoading, data: pageData } = API.useGetFrontPageQuery();

  const [popupOpen, setPopupOpen] = useState(true);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main>

          {pageData?.anchored && popupOpen && (
            <Popup
              data={pageData.anchored[0]}
              closePopup={() => setPopupOpen(false)}
            />
          )}

          {pageData &&
            pageData.blocks.map((pageBlock) => {
              switch (pageBlock.layout) {
                case "text":
                  return <IntroMain
                    data={pageBlock.text}
                    key={pageBlock.layout}/>;
                case "actions":
                  return <IntroCard
                    data={pageBlock.cards}
                    key={pageBlock.layout} />;
                case "news":
                  return <NewsSection
                    data={pageBlock.category}
                    key={pageBlock.layout}
                  />;
                case "cards":
                  return <Materials key={pageBlock.layout} />;
                case "card":
                  return <Banner key={pageBlock.layout} />;
                case "journal":
                  return (
                    <JournalSection
                      data={pageBlock.category}
                      key={pageBlock.layout}
                    />
                  );
                case "special":
                  return <Project key={pageBlock.layout} />;
              }
              return "";
            })}
        </main>
      )}
    </>
  );
};
