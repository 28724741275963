import { FC, useState, useEffect, useId } from "react";
import { NewsItem } from "../news-item/news-item";
import { CardsSlider } from "../cards-slider/cards-slider";

import type { TCategory } from "../../services/types/frontpage";

type TNewsSectionState = {
  title: string;
  textLink: string;
  sliderTitle: string;
  url: string;
};

type TNewsSectionProps = {
  data: TCategory | null;
};

export const NewsSection: FC<TNewsSectionProps> = ({data}) => {
  const [state, setState] = useState<TNewsSectionState>();
  const [newsList, setNewsList] = useState(Array<JSX.Element>);

  const id = useId();

  useEffect(() => {
    if (data) {
      setState({
        title: data.title,
        textLink: data.archive_link_label,
        sliderTitle: data.subtitle,
        url: data.slug,
      });

      setNewsList(
        data.items.map((item) => {
          return (
            <NewsItem
              date={item.date_published}
              tag={item.tags}
              text={item.annotation}
              image={item.cover || "нет изображения"}
              key={`${id + item.slug}`}
              path={item.slug}
            />
          );
        })
      );
    }
  }, [data, id]);

  return (
    <>
      {state && (
        <CardsSlider
          title={state.title}
          textLink={state.textLink}
          cards={newsList}
          sliderTitle={state.sliderTitle}
          to={state.url}
          slider={false}
        />
      )}
    </>
  );
};
