import { FC } from "react";
import { API } from "../../services/api/data";
import { useDispatch, useSelector } from "../../hooks";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  NEWS_PAGE_LIMIT_DESKTOP,
  NEWS_PAGE_LIMIT_MOBILE,
  NEWS_PAGE_LIMIT_TABLET,
} from "../../constants";
import {
  dataNewsSelector,
  pageNewsSelector,
  totalNewsSelector,
} from "../../services/selectors/news";
import { setNewsPage } from "../../services/slices/news";
import { LinkButton } from "../../components/link-button/link-button";
import { NewsItem } from "../../components/news-item/news-item";
import { Loader } from "../../components/loader/loader";
import { AudioPlayer } from "../../components/audio-player/audio-player";
import styles from "./news-page.module.css";

export const NewsPage: FC = () => {
  const dispatch = useDispatch();

  const page = useSelector(pageNewsSelector);
  const news = useSelector(dataNewsSelector);
  const total = useSelector(totalNewsSelector);

  const tablet = useMediaQuery("(max-width: 1023px)");
  const mobile = useMediaQuery("(max-width: 767px)");

  let pageLimit = NEWS_PAGE_LIMIT_DESKTOP;
  if (tablet) {
    pageLimit = NEWS_PAGE_LIMIT_TABLET;
  }
  if (mobile) {
    pageLimit = NEWS_PAGE_LIMIT_MOBILE;
  }

  const { isLoading, data } = API.useGetNewsQuery(
    { page, pageSize: pageLimit },
    { refetchOnMountOrArgChange: true }
  );

  const handleLoad = () => {
    dispatch(setNewsPage(page + 1));
  };

  if (isLoading) return <Loader />;

  return (
    <div className={styles.main}>

      <h1 className={styles.title}>{data?.title}</h1>
      {!isLoading && news && (
        <>
          <ul className={styles.container}>
            {news.map((item) => (
              <li key={item.slug}>
                <NewsItem
                  title={item.title}
                  text={item.annotation}
                  date={item.date_published}
                  tag={item.tags}
                  image={item.cover}
                  path={item.slug}
                />
              </li>
            ))}
          </ul>
          {total > news.length && (
            <div className={styles.button}>
              <LinkButton type={"button"} onClick={handleLoad}>
                Загрузить еще
              </LinkButton>
            </div>
          )}
          {total < news.length && (
            <div className={styles.button} />
          )}
        </>
      )}
    </div>
  );
};
