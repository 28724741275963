import { FC, useState, useEffect } from "react";
import { CardsSlider } from "../cards-slider/cards-slider";
import { JournalItem } from "../journal-item/journal-item";

import type { TCategory } from "../../services/types/frontpage";

type TJournalSectionState = {
  title: string;
  textLink: string;
  sliderTitle: string;
  url: string;
};

type TJournalSectionProps = {
  data: TCategory | null;
};

export const JournalSection: FC<TJournalSectionProps> = ({ data }) => {
  const [state, setState] = useState<TJournalSectionState>();
  const [journalsList, setJournalsList] = useState(Array<JSX.Element>);

  useEffect(() => {
    if (data) {
      setState({
        title: data.title,
        textLink: data.archive_link_label,
        sliderTitle: data.subtitle,
        url: data.slug,
      });

      setJournalsList(
        data.items.map((item) => {
          return (
            <JournalItem
              layout={item.layout}
              item={item}
              key={item.slug}
            />
          );
        })
      );
    }
  }, [data]);

  return (
    <>
      {state && (
        <CardsSlider
          title={state.title}
          textLink={state.textLink}
          cards={journalsList}
          sliderTitle={state.sliderTitle}
          to={state.url}
          slider={true}
        />
      )}
    </>
  );
};
