export const testBlockData: any = {
  "title": "Копирование документов",
  "blocks": [
    {
      "layout": "text",
      "warning": true,
      "contents": {
        "items": [
          "Автор этого дисклеймера не ведает, что творит, и добавляет важное сообщение, что информация отсюда не распространяется на все другие разделы прожито. См. подробно в разделе"
        ]
      },
      "link": {
        "url": "https://prozhito.org/",
        "description": " О проекте→",
      }
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Выбор типа копирования',
        "items": [
          "Оптимальным способом снять цифровую копию с рукописного текста является сканирование, однако кроме сканированных копий в архиве также хранятся фотокопии документов.",
          "Фотокопирование приемлемо в случае совпадения любых двух параметров из списка:"
        ]
      },
    },
    {
      "layout": "list",
      "contents": {
        "items": [
          "типичности документа (тиражность, информационная бедность), рабочего или чернового характера документа",
          "отсутствия визуальной ценности (рукописный текст без иллюстраций)",
          "четкости и яркости воспроизведения документа, гарантируещей читаемость при фотокопировании",
          "пункт 4"
        ]
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": '',
        "items": [
          "Наличие в документе рисунков или иных изображений является показанием к сканированию документа."
        ]
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Разрешение копирования и формат файла',
        "items": [
          "Оптимальным для архива является сканирование в формат .jpg с разрешением равным или превышающим 300 dpi.  Для уникальных документов, которые могут быть воспроизведены полиграфически полиграфически, а также для документов с плохим качеством сохранности информации (ветхие страницы, угасающие чернила или стертый карандаш), мелким или нечётким шрифтом, рекомендуется сканирование с разрешением от 300 до 1200 DPI. Повышение разрешения сканирования рекомендуется также для малоформатных документов, содержание которых может быть считано только при увеличении (миниатюры, контрольные отпечатки, фотографии в удостоверениях и пр.).",
          "Для документов, подходящих под параметры рекомендованных к фотокопированию (п. 4.2.1.) рекомендуется сохранение в формат .jpg с минимальной степенью компрессии."
        ]
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Полнота копирования',
        "items": [
          "Для работы нам нужна максимально полная копия документа со всеми содержащими информацию листами.",
        ]
      },
    },
    {
      "layout": "list",
      "contents": {
        "items": [
          "Если есть обложка, первые два кадра - обязательно содержат первую и вторую страницы обложки.",
          "После обложки (если она была), копируется каждая страница / разворот.",
          "Если начинается период пустых (не содержащих пометок) страниц, следует копировать пропуски до 5 страниц",
          "При пропусках больше 3 листов, их копировать не нужно. Следует пересчитать пустые страницы (или развороты - в зависимости от общего принципа нумерации копий): желательно сдвигать нумерацию кадров на количество пропущенных при копировании страниц. Большие пропуски следует отражать в поле 'Описание цифровой копии'.",
          "Всегда копируется предпоследняя страница и оборот обложки.",
          "Копирование рукописных документов должно производится со всеми вкладками и вклейками. ",
          "Вложения в документ копируются отдельно, но сохраняются и получают название в соответствие со своим местом в документе. Пример: при вложенной в тетрадь открытке сначала копируется тетрадный разворот, получающий номер Х в названии, после лицевая и оборотная часть открытки, получающие название Х-1 и Х-2.",
          "Если принципиально передать положение вложения, необходим дублирующий кадр, на котором вложение находится на своем месте в документе (пример: высушенное растение, вдавившееся в лист на развороте тетради)."
        ]
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Названия файлов',
        "items": [
          "При копировании документов рекомендуется в названии файла использовать фамилию автора, номер тетради и номер страницы. Якимович_дневник_13_2",
          "Сканы страниц следует разложить по папкам, названным как сканируемые документы."
        ]
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Дополнительные рекомендации к сканированию',
        "items": [
          "Перед началом копирования следует протереть стекло сканера, прижимное стекло фотостола и при наличии - фон.",
          "При сканировании нельзя пользоваться автоматическим кадрированием и обрезкой, а также “улучшающими” программными фильтрами.",
          "Границы копирования следует выставлять вручную, чтобы по краям листа оставались 3-5 мм поля.",
          "Развороты сшитых (переплетенных в тетради) листов можно копировать одним кадром.",
          "При сканировании разворотами стоит прижимать тетрадь к сканеру, чтобы место сгиба не выходило размытым.",
          "При утратах значительной площади страницы переплетенного документа, а также при высокой прозрачности материала страницы, она должна копироваться на фоне подложенного белого листа бумаги."
        ]
      },
    },
    {
      "layout": "image",
      "contents": {
        "url": "https://posibiri.ru/wp-content/uploads/2020/04/030420.jpg",
        "caption": "Подпись к картинке"
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "title": 'Дополнительные рекомендации к фотокопированию',
        "items": [
          "Телефон или фотоаппарат необходимо держать строго параллельно плоскости листа, так, чтобы края документа были параллельны краям кадра и были сохранены пропорции листа. Пожалуйста, следите за пропорциями текста и освещенностью листа, иначе проделанная вами работа может пропасть впустую!",
          "Документ должен лежать на хорошо освещенной поверхности, например — подоконнике или на столе под лампой, но так, чтобы тень от фотоаппарата не падала на текст. "
        ]
      },
    },
    {
      "layout": "image",
      "contents": {
        "url": "https://posibiri.ru/wp-content/uploads/2020/04/030420.jpg",
        "caption": "Подпись к картинке"
      },
    },
    {
      "layout": "text",
      "warning": false,
      "contents": {
        "items": [
          "Можно придерживать страницы пальцами или двумя линейками так, чтобы они не закрывали текста.",
          "В кадр параллельно одной из сторон документа рекомендуется класть канцелярскую линейку.",
          "Если переплетенный документ или книга не очень хорошо раскрывается, копировать следует по одной странице. "
        ]
      },
    },
    {
      "layout": "video",
      "contents": {
        "url": "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        "caption": "Подпись к видео"
      },
    }
  ]
}
