import { FC } from "react";
import { ExternalLink } from "../external-link/external-link";

import styles from "./headerNav.module.css";

import type { TMainMenu } from "../../services/types/context";

type THeaderNavProps = {
  desktop: boolean;
  open: boolean;
  links: TMainMenu[];
};

export const HeaderNav: FC<THeaderNavProps> = ({ desktop, open, links }) => {
  const isMobileMenuStyle = open
    ? styles.menu__mobile
    : `${styles.menu__mobile} ${styles.menu__mobile_open}`;

  const isMobileContainerStyle = open
    ? `${styles.menu__mobileContainer} ${styles.menu__mobileContainer_open}`
    : styles.menu__mobileContainer;

  const style = desktop ? styles.menu__desktop : isMobileMenuStyle;

  const secondLevel = links.filter((item) => item.children);

  return (
    <nav className={`${desktop ? "" : isMobileContainerStyle} header__nav`}>
      <ul className={style}>
        {links.map((item, index) => {
          return (
            <li className={styles.menu__item} key={index}>
              <ExternalLink
                url={
                  (item.title === "О проекте"
                    ? "./help"
                    : item.page_slug || item.url) ?? "#"
                }
                className={styles.menu__link}
              >
                {item.title}
              </ExternalLink>

              {desktop && item.children && item.children.length > 0 && (
                <ul className={styles.menu__dropdown}>
                  {item.children.map((item, index) => {
                    return (
                      <li className={styles.menu__dropdownItem} key={index}>
                        <ExternalLink
                          url={item.page_slug || item.url}
                          className={styles.menu__link}
                        >
                          {item.title}
                        </ExternalLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      {!desktop && secondLevel.length > 0 && (
        <ul
          className={`${
            secondLevel.length <= 3 ? style : styles.menu__mobile_secondLevel
          }`}
        >
          {secondLevel.map((item) => {
            return item.children?.map((child, index) => {
              return (
                <li className={styles.menu__item} key={index}>
                  <ExternalLink
                    url={(child.page_slug || child.url) ?? "#"}
                    className={styles.menu__link}
                  >
                    {child.title}
                  </ExternalLink>
                </li>
              );
            });
          })}
        </ul>
      )}
    </nav>
  );
};
