import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "./external-link.module.css";

type TExternalLinkProps = {
  url: string | null;
  children: ReactNode;
  className?: string;
};

export const ExternalLink: FC<TExternalLinkProps> = ({
  url,
  children,
  className = "",
}) => {
  if (url === null) {
    return null;
  }

  return url.startsWith("http") ? (
    <a
      href={url}
      className={`${styles.link} ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link to={url} className={`${styles.link} ${className}`}>
      {children}
    </Link>
  );
};
