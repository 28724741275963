import { FC } from "react";

import styles from "./content-text.module.css";

interface IContentText {
  title?: string;
  text?: string[];
  warning?: boolean;
  link?: {
    url?: string,
    description?: string
  };
}

//http://localhost:3000/guide для проверки
export const ContentText: FC<IContentText> = ({ text, title, warning, link }) => {

  return (
    <>
      {title &&
        <h2 className={styles.title}>{title}</h2>
      }
      {warning ? (text?.map((item) => {
        return (
          <div className={styles.container__warning}>
            <p className={styles.text}>
              <span className={styles.text__warning}>Внимание! </span>
              {item}
              <a className={styles.link__warning} href={link?.url}>{link?.description}</a>
            </p>
          </div>
        )
      }))
        : (text?.map((item) => {
          return (
            <p className={styles.text}>{item}</p>
          )
        }))
      }
    </>
  )
};
