import {FC} from "react";

import styles from "./content-image.module.css";

interface IContentImage {
  url?: string;
  caption?: string;
}

export const ContentImage: FC<IContentImage> = ({url, caption}) => {

  return (
    <>
      <figure className={styles.container}>
        <img className={styles.image} src={`${url}`} alt={`${caption}`}/>
        <figcaption className={styles.description}>{caption}</figcaption>
      </figure>
    </>
  )
};
