import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSample } from "../contents-sample/contents-sample";

import styles from "./contents-mobile.module.css";

import imageBurger from "../../../images/menu-mobil-open.svg";
import imgArrowLeft from "../../../images/Arrow_left.svg";
import imgArrowDown from "../../../images/arrow_down.svg"

import type { TContents } from "../../../services/types/page";
import closeImg from "../../../images/menu-mobil-close.svg";
import openImg from "../../../images/menu-mobil-open.svg";
import {API} from "../../../services/api/data";
import {HeaderNav} from "../../../components/headerNav/headerNav";
import {useMediaQuery} from "../../../hooks/useMediaQuery";

type TContentsMobileProps = {
  contents: TContents;
};

export const ContentsMobile: FC<TContentsMobileProps> = ({ contents }) => {
  const [contentsBurgerOpen, setContentsBurgerOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const btnState = menuOpen ? { img: closeImg, alt: "Закрыть" } : { img: openImg, alt: "Открыть" };

  const openMobileContents = (): void => {
    setContentsBurgerOpen(true);
  }

  const toggleMobileMenu = (): void => {
    setMenuOpen((prevState) => !prevState);
  };

  const { data } = API.useGetContextQuery();

  const returnBack = () => {
    navigate("/");
  }

  const desktop = useMediaQuery("(min-width: 768px)");

  return (
    <div className={styles.contents}>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.arrow__button}
          onClick={returnBack}
        >
          <img src={imgArrowLeft} alt="стрелка назад" />
        </button>
        <button
          type="button"
          className={styles.contents__button}
          onClick={openMobileContents}
        >
        <h2 className={styles.heading}>оглавление</h2>
        <img className={styles.contents__arrow} src={imgArrowDown} alt="Раскрыть оглавление" />
        </button>
        { data && <HeaderNav
          desktop={desktop}
          open={menuOpen}
          links={data.main_menu}
        />}
        <button
          type="button"
          className={styles.menu__button}
          onClick={toggleMobileMenu}
        >
          <img src={btnState.img} alt="кнопка меню" />
        </button>
        {
          contentsBurgerOpen && (
            <ContentsSample
              contents={contents}
              openContents={() => setContentsBurgerOpen(true)}
              closeContents={() => setContentsBurgerOpen(false)}
            />
          )
        }
      </div>
    </div>
  )
}
