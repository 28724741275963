import { FC } from "react";
import { Link } from "react-router-dom";
import { DOMAIN } from "../../constants";
import { ExternalLink } from "../external-link/external-link";

import styles from "./footer-info.module.css";

import type { TContext } from "../../services/types/context";

interface IFooterInfo {
  links: TContext | undefined;
}

export const FooterInfo: FC<IFooterInfo> = ({ links }) => {
  return (
    <section className={styles.info}>
      <div className={styles.logo}>
        {links?.logos.map((logo, index) => {
          if (index === 0) {
            return (
              <Link
                to="/"
                className={styles.link}
                key={logo.alt_text}
              >
                <img
                  className={styles.logo__main}
                  src={`${DOMAIN}${logo.icon}`}
                  alt={logo.alt_text}
                />
              </Link>
            );
          } else {
            return (
              <a
                key={logo.alt_text}
                href={logo.url || ""}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <img
                  className={styles.logo__eusp}
                  src={`${DOMAIN}${logo.icon}`}
                  alt={logo.alt_text}
                />
              </a>
            );
          }
        })}
      </div>
      <section className={styles.social}>
        <ul className={styles.links}>
          {links?.footer_social?.slice().reverse().map((link) => (
            <li className={styles.item} key={link.title}>
              <a
                href={link.url || ""}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <img
                  src={`${DOMAIN}${link.icon}`}
                  className={styles.icon}
                  alt={link.title}
                />
                <span className={styles.text}>{link.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.legal}>
        <ul className={styles.legals}>
          {links?.footer_links.map((link) => (
            <li className={styles.item} key={link.title}>
              <ExternalLink url={link.page_slug ?? link.url} className={styles.link}>
                {link.title}
              </ExternalLink>
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
};
