import { FC } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../../services/api/data";

import { SamplePage } from "../../pages/sample-page/sample-page";
import { NotFoundPage } from "../../pages/not-found-page/not-found-page";

export const ServerRoute: FC = () => {
  const location = useLocation();

  const { isLoading, data } = API.useGetPageBySlugQuery(
    location.pathname.slice(1)
  );

  return !isLoading && !data ? <NotFoundPage /> : <SamplePage />;
};
