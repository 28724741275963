import React, {useState} from 'react';
import styles from './accordion-row-api.module.css'
import {TLink} from "../../services/types/help";
import { Link } from 'react-router-dom';

interface IAccordionProps {
  title: string,
  content?: TLink[],
  isExpanded?: boolean
}

const AccordionRowApi = ({title, content = [], isExpanded = false}: IAccordionProps) => {

  const [isActive, setIsActive] = useState(isExpanded);

  return (
    <div className={styles.accordion__row}>
      <div className={`${styles.accordion__title} ${isActive && styles.accordion__title_active}`}
           onClick={() => setIsActive(!isActive)}>
        <div className={`${styles.accordion__arrow} ${isActive && styles.accordion__arrow_opened}`}></div>
        <div>{title}</div>
      </div>
      <div className={styles.accordion__content} aria-expanded={isActive}>
        <ul className={styles.accordion__list}>
          {content.map((navLink) =>
            <li key={navLink.slug}>
              <Link to={`/guide/${navLink.slug}`} className={styles.accordion__link}>{navLink.title}</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AccordionRowApi;
