import { FC } from "react";
import { LinkButton } from "../link-button/link-button";

import styles from "./intro-item.module.css";

import type { TLink } from "../../services/types/frontpage";

export interface IntroItemProps {
  title: string;
  text: string;
  link: TLink;
}

export const IntroItem: FC<IntroItemProps> = ({ title, text, link }) => {
  return (
    <li className={styles.intro__card}>
      <h3 className={styles.intro__cardTitle}>{title}</h3>
      <p className={styles.intro__cardSubtitle}>{text}</p>
      <div className={styles.intro__linkContainer}>
        <p className={styles.intro__aboutLink}>{link.label}</p>
        <LinkButton to={link.page_slug ?? link.url} round size="large" />
      </div>
    </li>
  );
};
