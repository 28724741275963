import { FC, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { DOMAIN } from "../../constants";
import { API } from "../../services/api/data";
import { HeaderNav } from "../headerNav/headerNav";

import styles from "./header.module.css";

import openImg from "../../images/menu-mobil-open.svg"
import closeImg from "../../images/menu-mobil-close.svg";

export const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const desktop = useMediaQuery("(min-width: 768px)");
  const location = useLocation();

  const { data } = API.useGetContextQuery();

  const btnState = menuOpen
    ? { img: closeImg, alt: "Закрыть" }
    : { img: openImg, alt: "Открыть" };

  const toggleMobileMenu = (): void => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <header
      className={`${
        menuOpen
          ? `${styles.header} ${styles.header_theme_dark}`
          : styles.header
      } ${location.pathname !== "/" ? styles.header_theme_white : null}`}
    >
      {data && (
        <div className={`${styles.header__container} ${styles.menu}`}>
          <div className={styles.header__links}>
            {data.logos.map((logo, index) => {
              if (index === 0)
                return (
                  <Link to="/" className={styles.link} key={index}>
                    <img
                      src={`${DOMAIN}${logo.icon}`}
                      alt={logo.alt_text}
                      className={styles.header__logo}
                    />
                  </Link>
                );
              else
                return (
                  <a
                    href="https://eusp.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    key={index}
                  >
                    <img
                      src={`${DOMAIN}${logo.icon}`}
                      alt={logo.alt_text}
                      className={styles.header__eusp}
                    />
                  </a>
                );
            })}
          </div>
          <HeaderNav desktop={desktop} open={menuOpen} links={data.main_menu} />
          {!desktop && (
            <button
              type="button"
              className={styles.menu__button}
              onClick={toggleMobileMenu}
            >
              <img alt={btnState.alt} src={btnState.img} />
            </button>
          )}
        </div>
      )}
    </header>
  );
};
