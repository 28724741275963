import React from "react";
import styles from './video.module.css'

export const Video = () => {

  return (
    <div className={styles.video}>
      <div className={styles.video__container}>
        <iframe className={styles.video__iframe}
          src="https://www.youtube.com/embed/arj7oStGLkU?controls=0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
        <figcaption className={styles.video__figCaption}>
          Видео, где Михаил Мельниченко сам фотографирует, как надо, а не как хочется
        </figcaption>
      </div>
    </div>
  )
}
