import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {API} from "../api/data";
import newsReducer from "../slices/news";
import journalReducer from "../slices/journal";
import searchReducer from "../slices/search";

export const rootReducer = combineReducers({
  news: newsReducer,
  journal: journalReducer,
  search: searchReducer,
  [API.reducerPath]: API.reducer,
})
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    API.middleware
  ]),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
})

export default store;
