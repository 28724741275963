import { FC, ReactNode } from "react";
import { useLocation } from "react-router";
import { Breadcrumbs } from "../breadcrumbs/breadcrumbs";
import { Footer } from "../footer/footer";
import { Header } from "../header/header";

export interface LayoutProps {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => {

  const { pathname } = useLocation();

  return (
    <>
      <Header />
      {pathname !== "/" && pathname !== "/help" && pathname !== "/guide-components" && <Breadcrumbs />}
      {children}
      <Footer />
    </>
  )
}
