import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getDefaultHeaders } from "./index";
import { API_URL } from "../../constants";

import type { TContext } from "../types/context";
import type { TFrontPage } from "../types/frontpage";
import type { TPageData } from "../types/page";
import type { TCategoryData, TCategoryParams } from "../types/category";
import type { TJournalResponse } from "../types/journal";
import type { THelpPage } from "../types/help";

import type { TGuidePage } from "../types/help";

export const API = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => getDefaultHeaders(headers),
  }),
  endpoints: (build) => ({
    getContext: build.query<TContext, void>({
      query: () => ({
        url: "/landing/context/",
      }),
    }),
    getFrontPage: build.query<TFrontPage, void>({
      query: () => ({
        url: "/landing/frontpage/",
      }),
    }),
    getPageBySlug: build.query<TPageData, string | undefined>({
      query: (slug) => ({
        url: `/landing/page/${slug}`,
      }),
    }),
    getNews: build.query<TCategoryData, TCategoryParams>({
      query: ({ tag, page, pageSize }) => ({
        url: `/landing/category/news`,
        params: {
          tag,
          page,
          pageSize,
        },
      }),
    }),
    getJournal: build.query<TJournalResponse, TCategoryParams>({
      query: ({ tag, page, pageSize }) => ({
        url: "/landing/category/journal",
        params: {
          tag,
          page,
          pageSize,
        },
      }),
    }),
    getArchive: build.query<any, any>({
      query: (param) => ({
        method: 'GET',
        url: `/help/search/`,
        params: {
          s: param
        }
      }),
    }),
    getBreadcrumps: build.query<TCategoryData & TPageData, { part: string, slug: string }>({
      query: ({ part, slug }) => ({
        url: `/landing/${part}/${slug}`,
      }),
    }),
    getHelpPage:build.query<THelpPage, void>({
      query: () => ({
        url: "/help/index/",
      }),
    }),
    getHelpPageBySlug: build.query<TGuidePage, string | undefined>({
      query: (slug) => ({
        url: `/help/page/${slug}/`,
      })
    })
  })
})


