import { FC } from "react";
import { TabsItem } from "../tabs-item/tabs-item";

import styles from "./tabs.module.css";

import type { TJournalTabs } from "../../services/types/journal";

type TProps = {
  tabs: TJournalTabs;
  onClick: (value: string) => void;
  current: string;
}

export const Tabs: FC<TProps> = ({ tabs, onClick, current }) => {
  return (
    <>
      <p className={styles.heading}>Сортировать :</p>
      <ul className={styles.tabs}>
        {
          tabs.map((tab, index) => (
            <TabsItem
              key={index}
              title={tab.title}
              value={tab.slug}
              selected={current === tab.slug}
              setSelected={onClick}
            />
          ))
        }
      </ul>
    </>
  )
}
